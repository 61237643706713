import React from "react";
import logo from "../assets/atlas-logo-icon.png";
import "./AtlasSpinner.css";

const AtlasSpinner = ({ height }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={logo} className="App-logo" alt="logo" style={{ height }} />
    </div>
  );
};

export default AtlasSpinner;
