import React, { createContext, useState } from "react";

const MapSettingsContext = createContext([{}, () => {}]);

const MapSettingsProvider = ({ children }) => {
  // Default maptiles settings
  const [state, setState] = useState({
    // Tileset to fetch from mapbox
    tiles: "mapbox://styles/mapbox/dark-v10",
    // Whether or not the icons on map should be color coded
    iconMask: true,
    // logged in users email address. Should probably keep this in local storage
    // email: '',
  });

  return (
    <MapSettingsContext.Provider value={[state, setState]}>
      {children}
    </MapSettingsContext.Provider>
  );
};

export { MapSettingsContext, MapSettingsProvider };
