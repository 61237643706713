import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { Auth } from 'aws-amplify';
import { createHttpLink } from '@apollo/client';

// Function to refresh tokens using AWS Amplify
export const refreshTokens = async () => {
  try {
    await Auth.currentAuthenticatedUser({ bypassCache: true }); // Renew tokens automatically
    console.log('>>>>>>>>>>>> refreshTokens called' + Date.now());
    // Schedule the next token check after a certain duration (e.g., 60 seconds)
    setTimeout(refreshTokens, 600 * 1000); // Refresh tokens after 10 mins
  } catch (error) {
    console.error('Error refreshing tokens:', error);
  }
};

// Check token expiration and renew if needed
const checkTokenExpiration = async () => {
  try {
    const session = await Auth.currentSession();
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = session.getIdToken().getExpiration() - 60; // Refresh token 60 seconds before expiration

    if (currentTime >= expirationTime) {
      await refreshTokens();
    }
  } catch (error) {
    console.error('Error checking token expiration:', error);
  }
};

const authLink = setContext(async (_, { headers }) => {
  // Get the current authenticated user from AWS Cognito
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken()?.getJwtToken();
    // Set the token in the request headers
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  } catch (error) {
    console.error('Error getting token:', error);
    return {
      headers,
    };
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

// Create the Apollo Client instance

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
