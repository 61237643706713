import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import { initSentry } from './libs/errorLib';
import { MapSettingsProvider } from './context/SettingsContext';
import { apolloClient, refreshTokens } from './libs/client';

initSentry();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
});

// Call refreshTokens to start the token refresh cycle before rendering the app
refreshTokens();

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Router>
      <MapSettingsProvider>
        <App />
      </MapSettingsProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
