// import * as Sentry from "@sentry/browser";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const isLocal = process.env.NODE_ENV === 'development';

export function initSentry() {
  //For now, I will remove the isLocal checks. I want to log all errors,
  //coming both from AWS and locally running instances.

  // if (isLocal) {
  //   return;
  // }

  Sentry.init({
    dsn:
      'https://7f9d4424be744080a5895e14cbc3c6e3@o443048.ingest.sentry.io/5416012',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export function logError(error, errorInfo = null) {
  // if (isLocal) {
  //   return;
  // }
  const loggedInUser = localStorage.getItem('logged-in-user');
  console.log(loggedInUser);
  Sentry.setUser({ email: loggedInUser });

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

export function onError(error) {
  let errorInfo = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  alert(message);
}
