import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AtlasSpinner from "./components/AtlasSpinner";

// Lazy / Suspense for Route-based code splitting
// (we only download code for the route user is requesting.
// Showing Spinner before it is fully loaded.)

const Home = lazy(() => import("./containers/Home"));
const VehicleMap = lazy(() => import("./containers/VehicleMap"));
const Login = lazy(() => import("./containers/Login"));
const Signup = lazy(() => import("./containers/Signup"));
const FleetManagement = lazy(() =>
  import("./containers/FleetManagement/FleetManagement")
);
const Settings = lazy(() => import("./containers/Settings"));
const NotFound = lazy(() => import("./containers/NotFound"));
const ResetPassword = lazy(() => import("./containers/ResetPassword"));

export default function Routes({ appProps }) {
  return (
    <Suspense fallback={<AtlasSpinner height={"40vmin"} />}>
      <Switch>
        {/* <AppliedRoute path="/" exact component={Home} appProps={appProps} /> */}
        {/* For now, let's make the vehicle map the landing page as well */}
        <AppliedRoute
          path="/"
          exact
          component={appProps.isAuthenticated ? VehicleMap : Home}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path="/login"
          exact
          component={Login}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path="/signup"
          exact
          component={Signup}
          appProps={appProps}
        />
        <UnauthenticatedRoute exact path="/login/reset">
          <ResetPassword />
        </UnauthenticatedRoute>
        <AuthenticatedRoute
          path="/settings"
          exact
          component={Settings}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/fleet"
          exact
          component={FleetManagement}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/map"
          exact
          component={VehicleMap}
          appProps={appProps}
        />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}
